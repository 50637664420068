import { useEffect, useState } from "react";
// import { connectWallet,
//   getCurrentWalletConnected
//  } from "./utils/interact.js";

 import env from "react-dotenv";
 import { isMobile } from 'react-device-detect';
 import { ethers } from "ethers";
 import { Alchemy, Network } from 'alchemy-sdk';
 import DynamoDB from 'aws-sdk/clients/dynamodb';
 
 require('dotenv').config();
 
 const dbb = new DynamoDB({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, region: 'us-west-2' });





 const apiKey = process.env.REACT_APP_TOKEN_APIKEY;

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [validWallet, setValidWallet] = useState("");

  var browser = "";
  if(isMobile){
    var browser = "TRUE";
  }
  else{
    var browser = "FALSE";
  }
  
  
  const settings = {
    apiKey: apiKey,
    network: Network.ETH_MAINNET,
  };

  const web3 = new Alchemy(settings);

  

  useEffect(() => {
    // wrap your async call here
    const loadData = async () => {

      //buildAgeForm();

      

      // fetch('https://api-goerli.etherscan.io/api?module=contract&action=getabi&address=0xac63f537964b0c98141bdd676c7ae12d5de7788b')
      //   .then(response => response.json())
      //   .then(data => {
      //       console.log("DATA: "+JSON.stringify(customData.result));
      //       const contract = new ethers.Contract("0xdd9d52d87c1d594a3c9f071330ffa2997926c268",customData.result,"0x2231b0188dad7349695dc84b8fe5d1bee5e79cfe");
      //       // console.log(JSON.stringify(contract));
      //   });

      //document.addEventListener('contextmenu', event => event.preventDefault());
      // const { address, status } = await getCurrentWalletConnected();
      // setWallet(address)
      // setStatus(status);

      home();

      addWalletListener();

    };

    // then call it here
    loadData();
  }, []);

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    verifyWallet();

  };

  


  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verifying...</h1>,
          address: addressArray[0],
        };
        
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" style="color: white !important;" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {

         
          return {
            address: addressArray[0],
            status: <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verify You're A Humie</h1>
            <p className="paragraph">Click the button below to connect your MetaMask wallet. Please note that we are only verifying your wallet contains a Hume Genesis in order to grant you access to the shop. Thanks Humie.</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
          
          

        } else {
          return {
            address: "",
            status: <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verify You're A Humie</h1>
            <p className="paragraph">Click the button below to connect your MetaMask wallet. Please note that we are only verifying your wallet contains a Hume Genesis in order to grant you access to the shop. Thanks Humie.</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
        if(isMobile){
          return {
            address: "",
            status: (
              <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Browsing on mobile?</h1>
                <p className="paragraph">Click the button below to open this webpage in the Metamask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/humieshop.wearehume.com/" >
                    Open in Metamask
                  </a>
              </div>
            ),
          };
        }
        else{
          return {
            address: "",
            status: (
              <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">No Metamask extension found</h1>
                <p className="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
            ),
          };
        }
      }
  };

  const onMintPressed = async () => { //TODO: implement
    
  };

  const verifyWallet = async () => {
    const {address, status} = await getCurrentWalletConnected();

    //const address = "0x95e937350bcfe2baeff9580f6a09db82340a2905";

    console.log(" Address: "+address.length);

    if ( address.length == 0 ){
      console.log("TRUE SHOW HOME");
      setWallet("");
          setStatus(
            <div className="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verify You're A Humie</h1>
              <p className="paragraph">Click the button below to connect your MetaMask wallet. Please note that we are only verifying your wallet contains a Hume Genesis in order to grant you access to the shop. Thanks Humie.</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        return false;
    }
    
    else{
    //const nfts = await web3.alchemy.getNfts({owner: address});
    const nfts = await web3.nft.getNftsForOwner(address, {
      contractAddresses: ["0xef44e83db68b9e6df9ce6cc9343653c733762617","0x0301e208ec282ec38934606ef53dbd5876ed7eb0"]
    });

   
    const whiteList = ["0x4cb5235A87f47293983d87943573816B09604d27","0x2231b0188dad7349695dc84b8fe5d1bee5e79cfe","0x282672eda77623459c49066c1531dde8edb5bd75","0x916531cfef552aa0e151b1c3c8191b5961c9b743"];
    
    // var retString = "";
    // Print owner's wallet address:
    // retString = retString + "fetching NFTs for address: ";
    // retString = retString + address;
    // retString = retString + "\n...\n";

    // Print total NFT count returned in the response:
    // retString = retString + "number of NFTs found:";
    // retString = retString + nfts.totalCount;
    // retString = retString + "...";
    

    

    var params = {
      TableName: 'Hume-Burn',
      FilterExpression: "walletAddress = :walletAddress",
    // Define the expression attribute value, which are substitutes for the values you want to compare.
    ExpressionAttributeNames: {
      "#WA": "walletAddress"
      },
    ExpressionAttributeValues: {
      ":walletAddress": {S: address}
    },
    // Set the projection expression, which are the attributes that you want.
    ProjectionExpression: "#WA"
      
    };

    dbb.scan(params, function (err, data) {
      if (err) {
        //console.log("Error", err);
      } else {
        console.log("Success", data.Items);
        var foundNFT = false;
        
        if (data.Items.length > 0) {
          denyWallet();
          console.log("DENIED FIRST");
          return foundNFT;
        }
        else {
          // Print contract address and tokenId for each NFT:
          for (const nft of nfts.ownedNfts) {
            // retString = retString + "===";
            // retString = retString + "contract address:";
            // retString = retString + nft.contract.address;
            // retString = retString + "token ID:";
            // retString = retString + nft.id.tokenId;
            console.log(" TOKEN ++ "+ JSON.stringify(nft) );
            
              
              //retString = retString + "FOUND NFT";
              foundNFT = true;
              
            
            


            // const response = await web3.alchemy.getNftMetadata({
            //   contractAddress: "0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d",
            //   tokenId: nft.id.tokenId
            // });
            // var resString = JSON.stringify(response)
            // retString = retString + resString;
          }

          
          // retString = retString + "===";

          if ( whiteList.indexOf(address) !== -1 ){

            foundNFT = true;
          }



          if(foundNFT === true){
            console.log("GAMMA FOUND");
            allowWallet(address);
          }
          else {
            denyWallet();
            console.log("DENIED FIRST");
          }

          // if(foundNFT == true){
          //   allowWallet();

          // }
          // else{
          //   denyWallet();

          // }

          return foundNFT;
                
        }
      }
    });

    
  }
  }

  function printRetString (walletString){
    setStatus(JSON.stringify(walletString));

  }




  function home() {
    setStatus(
      <div className="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verify You're A Humie</h1>
              <p className="paragraph">Click the button below to connect your MetaMask wallet. Please note that we are only verifying your wallet contains a Hume Genesis in order to grant you access to the shop. Thanks Humie.</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
    );
  }

  function allowWallet(address){
    console.log(address);
    var url = "https://shop.wearehume.com/?key="+address;
    setStatus(
      <div>
        <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">You're Verified Humie!</h1>
            <p className="paragraph">You're all set. Click the button below to enter the shop.</p>
            
        </div>
        <div className="button-container"><a href={url} className="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
  function allowOmega(){
    setStatus(
      <div>
        <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Omega Verified!</h1>
            <p className="paragraph">Congratulations! You're eligible to enter the Omega Shop. Click the button below to enter.</p>
            
        </div>
        <div className="button-container"><a href="https://shop.quirkies.io/?key=6217655344097" className="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
  function allowGamma(){
    setStatus(
      <div>
        <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Gamma Verified!</h1>
            <p className="paragraph">Congratulations! You're eligible to enter the Gamma Shop. Click the button below to enter.</p>
            
        </div>
        <div className="button-container"><a href="https://shop.quirkies.io/?key=6217655344097" className="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
  function allowDelta(){
    setStatus(
      <div>
        <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Delta Verified!</h1>
            <p className="paragraph">Congratulations! You're eligible to enter the Delta Shop. Click the button below to enter.</p>
            
        </div>
        <div className="button-container"><a href="https://shop.quirkies.io/?key=6217655344097" className="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
 
  function denyWallet(){
    setStatus(
      <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Denied!</h1>
            <p className="paragraph">This wallet is not eligible for this drop. Open MetaMask and connect to a different wallet.</p>
            
        </div>
    );
  }
  
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(<h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verifying...</h1>);
          
          verifyWallet();
          
          
        } else {
          setWallet("");
          setStatus(
            <div className="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verify You're A Humie</h1>
              <p className="paragraph">Click the button below to connect your MetaMask wallet. Please note that we are only verifying your wallet contains a Hume Genesis in order to grant you access to the shop. Thanks Humie.</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        }
      });
    } else {
      if(isMobile){
        setStatus(
          <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Browsing on mobile?</h1>
                <p className="paragraph">Click the button below to open this webpage in the Metamask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/humieshop.wearehume.com/" >
                    Open in Metamask
                  </a>
              </div>
        );
      }
      else{
        setStatus(
          <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">No Metamask extension found</h1>
                <p className="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
        );
      }
    }
  }

  return (
    <div className="Minter">
      

      
      <div id="status">
        {status}
      </div>

      

    </div>
  );
};

export default Minter;
